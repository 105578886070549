export const ApplicationName = "AutoSdms.Client";

/**
 * Authorization のパスリスト
 */
export const AuthorizationPaths = {
  /**
   * usr login path (with cookie)
   */
  Login: "/api/Authorization/login",
  /**
   * 現在ログインしているユーザ情報の取得
   */
  CurrentUser: "/api/ApplicationUser/current",
  /**
   * user logout path (with cookie)
   */
  Logout: "/api/Authorization/logout",
};
