import APP from "./App";
import REPORTS from "./Reports";
import PROJECTS from "./Projects";
import EQUIPMENTS from "./Equipments";
import TEMPLATES from "./Templates";
import USERS from "./Users";
import LOGIN from "./Login";
import ERROR from "./Error";
import MODAL from "./Modal";
export default {
  LANGUAGE_CODE: "ja",
  APP,
  REPORTS,
  PROJECTS,
  EQUIPMENTS,
  TEMPLATES,
  USERS,
  LOGIN,
  ERROR,
  MODAL,
};
