import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import { Authorize } from "@/components/api-authorization/AuthorizeService";

export async function authorize(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
): Promise<void> {
  try {
    if (Authorize.isAuthenticated.value) await Authorize.refreshUser();
  } catch (e) {
    console.error(e);
  }
  console.log("guard isAuthenticated:%o", Authorize.isAuthenticated.value);
  if (Authorize.isAuthenticated.value) {
    next();
    return;
  }
  try {
    await Authorize.logout();
  } catch (e) {
    console.error(e);
  }
  const redirect = to.fullPath;
  console.info("redirect login.", { redirect });
  next({ name: "Login", params: { redirect } });
}
