import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createI18n } from "vue-i18n";
import messages from "./lang/index";
import "@fortawesome/fontawesome-free/js/fontawesome";
import "@fortawesome/fontawesome-free/js/solid";
import "@fortawesome/fontawesome-free/js/regular";
import "bootstrap";
import "@/assets/scss/style.scss";
import moment from "moment";

//言語コード取得
const languageCode = window.navigator.language.substring(0, 2);

const i18n = createI18n({
  legacy: false,
  // vue-i18nのオプションをここに記述
  locale: languageCode,
  fallbackLocale: "ja",
  messages: messages,
});
moment.locale(languageCode ?? "jp");

createApp(App).use(router).use(i18n).mount("#app");
