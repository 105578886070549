export default {
  TITLE: "報告書一覧",
  MODAL_TITLE: "報告書情報",
  USER_NAME: "測定者名",
  EQUIPMENT: "測定機器",
  TEMPLATE: "報告書テンプレート",
  PROJECT: "プロジェクト",
  START: "測定開始日",
  END: "測定終了日",
  CREATE_AT: "作成日",
  UPDATE_AT: "更新日",
  IS_NOT_UPLOAD: "クラウド未同期",
  IS_PRIVATE: "情報資産レベルⅡ以上",
  SEARCH: "検索",
  INFORMATION: "報告書情報",
  OPTION: "オプション",
  DATE_FORMAT: "YYYY/MM/DD",
  OPEN_DETAIL: "詳細画面表示",
  UPLOAD: "クラウド同期",
  DOWNLOAD: "ダウンロード",
  SAVE: "更新",
  DELETE: "削除",
  OPTIONS: {
    NAME: "オプション名",
    TYPE: "オプションタイプ",
    VALUE: "オプションの値",
  },
  OPTION_TYPES: {
    0: "文字列",
    1: "日付",
    2: "整数",
    3: "小数",
    4: "真偽値",
    UNKNOWN: "不明な型",
  },
  ORDER: "並び替え",
  ORDERLIST: {
    0: "測定者名",
    1: "機器名",
    2: "報告書テンプレート",
    3: "プロジェクト",
    4: "測定開始日(昇順)",
    5: "測定開始日(降順)",
    6: "測定終了日(昇順)",
    7: "測定終了日(降順)",
    8: "作成日(昇順)",
    9: "作成日(降順)",
    10: "更新日(昇順)",
    11: "更新日(降順)",
  },
  IS_PRIVATE_CONFIRM: {
    ENABLE:
      "情報資産レベルⅡ以上 に チェックを入れた場合はクラウドサーバーアクセス時に該当の報告書は表示されなくなります。本当によろしいですか？",
    DISABLE:
      "クラウドサーバーにも同期され 表示されるようになります。本当によろしいですか？",
  },
  CANCEL_TEMPLATE_SELECTION_IF_PROJECT_SELECTED:
    "選択した報告書テンプレートはプロジェクトと不整合が生じています。",
  UPDATE_CONFIRM: "更新してもよろしいですか？",
  DELETE_CONFIRM: "本当に削除してもよろしいですか？",
  UPLOAD_CONFIRM: "クラウドへの同期を実行しますか？",
  NOT_FOUND_TEMPLATE_CONFIRM:
    "報告書テンプレートファイルが存在しません。測定結果ファイルのみをダウンロードしますか？",
  NO_VALIDATE_TEMPLATE_CONFIRM:
    "報告書テンプレートのフォーマットが適切ではありません。測定結果の挿入がされませんがダウンロードしますか？",
  NOT_PROJECT_TEMPLATE_CONFIRM:
    "報告書テンプレートがプロジェクト対応ではありません。報告書が正常に表示されない可能性がありますが、ダウンロードしますか？",
  PROJECT_DOWNLOAD_TITLE: "プロジェクトとしてダウンロード",
  BUTTON_LABEL: "⋮",
  SEARCH_CONDITION: "検索条件",
  SEARCH_RESULT: "検索結果",
  NOT_FOUND_DETAIL: "対象の報告書が取得できませんでした",
};
