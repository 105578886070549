export default {
  TITLE: "報告書テンプレートマスタ",
  MODAL_TITLE: {
    ADD: "報告書テンプレート追加",
    INFO: "報告書テンプレート情報",
  },
  SEARCH_CONDITION: "検索条件",
  SEARCH_RESULT: "検索結果",
  EQUIPMENT: "測定機器",
  FILE_NAME: "ファイル名",
  FILE: "ファイル",
  IS_NOT_UPLOAD: "クラウド未同期",
  ORDERLIST: {
    0: "測定機器名",
    1: "ファイル名",
  },
  OPTION_TYPES: {
    0: "文字列",
    1: "日付",
    2: "整数",
    3: "小数",
    4: "真偽値",
    7: "期間",
    UNKNOWN: "不明な型",
  },
  MENU: {
    OPEN: "詳細画面表示",
    UPLOAD: "クラウド同期",
    DOWNLOAD: "ダウンロード",
    CREATE_FROM: "コピーして作成",
  },
  ADD_BUTTON_TITLE: "報告書テンプレート追加",
  INVALID_TEMPLATE_EXTENSION: "報告書テンプレートは .xlsx のみ対応しています。",
  INVALID_TEMPLATE_CONFLICT:
    "報告書テンプレートファイル {filename} は既にその機器上に存在します。",
  INVALID_TEMPLATE_FILE_FORMAT: "報告書テンプレートファイルとして不正です。",
  IS_PROJECT: "プロジェクト",
  IS_PROJECT_MESSAGE: "このファイルはプロジェクトに該当します",
  USE_ADDITIONAL: "追加情報",
  TAG: "タグ",
  OPTION_TITLE: "オプション",
  OPTION_ADD_TITLE: "オプション追加",
  BUTTONS: {
    UPDATE: "更新",
    DELETE: "削除",
    CREATE: "追加",
  },
  OPTION_DELETE_TITLE: "オプション削除",
  OPTION_UP_TITLE: "上のオプションと入替",
  OPTION_DOWN_TITLE: "下のオプションと入替",
  TABLE: {
    OPTION_NAME: "オプション名",
    OPTION_TYPE: "オプションタイプ",
  },
  CREATE_CONFIRM: "データを追加します。よろしいですか？",
  UPDATE_CONFIRM: "更新してもよろしいですか？",
  DELETE_CONFIRM: "本当に削除してもよろしいですか？",
  UPLOAD_CONFIRM: "クラウドへの同期を実行しますか？",
  REPORT_HAS_ALREADY_BEEN_CREATED_CONFIRM:
    "既に報告書が作成されていますが、更新してもよろしいですか？",
  FILE_NAME_LENGTH_OVER: "ファイル名が {maxlength} 文字よりも多いです",
  OPTION_VALIDITY_MESSAGE: "{title} は既に定義されています",
};
