export default {
  SYSTEM: "システムエラーが発生しました",
  AUTHENTICATION: "認証に失敗しました",
  CREATE: "追加に失敗しました。",
  UPDATE: "更新に失敗しました。",
  DELETE: "削除に失敗しました。",
  UPLOAD: "同期に失敗しました。",
  FILE_NOT_FOUND: "ファイルが見つかりませんでした。",
  DONWLOAD_FAIL: "ダウンロードに失敗しました。",
  NOT_FOUND: "データが存在しませんでした。",
  NETWORK: "ネットワークエラー",
};
