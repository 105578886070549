export default {
  TITLE: "測定機器マスタ",
  MODAL_TITLE: {
    ADD: "測定機器追加",
    INFO: "測定機器情報",
  },
  SEARCH_CONDITION: "検索条件",
  SEARCH_RESULT: "検索結果",
  EQUIPMENTS_NAME: "測定機器名",
  IS_NOT_UPLOAD: "クラウド未同期",
  SEARCH: "検索",
  OPEN_DETAIL: "詳細画面表示",
  UPLOAD: "クラウド同期",
  UPDATE_CONFIRM: "更新してもよろしいですか？",
  ADD_CONFIRM: "データを追加します。よろしいですか？",
  UPLOAD_CONFIRM: "クラウドへの同期を実行しますか？",
  BUTTON_LABEL: "⋮",
  SAVE: "更新",
  ADD: "追加",
  ADD_BUTTON_TITLE: "測定機器追加",
  NOT_FOUND_DETAIL: "対象の測定機器が取得できませんでした",
};
