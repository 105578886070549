import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { authorize } from "./guard";
const requireLoginMetadata = { requiresLogin: true };
import { RootLoading as loading } from "@/components/Loading";
import { isLoginRouting } from "@/plugins/axios";
const routes: Array<RouteRecordRaw & { requiresLogin?: boolean }> = [
  {
    path: "/",
    name: "Root",
    redirect: "/reports",
  },
  {
    path: "/login",
    name: "Login",
    props: true,
    component: () => import("@/views/Login.vue"),
  },
  {
    path: "/reports",
    name: "Reports",
    props: (route) => Object.assign({}, route.query, route.params),
    component: () => import("@/views/Reports/index.vue"),
    meta: requireLoginMetadata,
  },
  {
    path: "/users",
    name: "Users",
    props: (route) => Object.assign({}, route.query, route.params),
    component: () => import("@/views/Users/index.vue"),
    meta: requireLoginMetadata,
  },
  {
    path: "/equipments",
    name: "Equipments",
    props: (route) => Object.assign({}, route.query, route.params),
    component: () => import("@/views/Equipments/index.vue"),
    meta: requireLoginMetadata,
  },
  {
    path: "/projects",
    name: "Projects",
    props: (route) => Object.assign({}, route.query, route.params),
    component: () => import("@/views/Projects/index.vue"),
    meta: requireLoginMetadata,
  },
  {
    path: "/templates",
    name: "Templates",
    props: (route) => Object.assign({}, route.query, route.params),
    component: () => import("@/views/Templates/index.vue"),
    meta: requireLoginMetadata,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  loading.add("router");
  console.log("router beforeEach");
  const requireLogin = to.matched.some((x) => !!x.meta?.requiresLogin);
  if (!requireLogin) {
    next();
    return;
  }
  await authorize(to, from, next);
});
router.afterEach((arg) => {
  loading.delete("router");
  console.log("router afterEach");
  if (arg.name === "Login") {
    isLoginRouting.value = false;
  }
});

export default router;
