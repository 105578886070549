import { Disposable, Listener, TypedEvent } from "./TypedEvent";

export class AllClearService {
  private readonly onClearEmitter = new TypedEvent<void>();
  /**
   * クリアする
   */
  clear(): void {
    this.onClearEmitter.emit();
  }
  /**
   * イベントを登録する
   * @param type イベントタイプ
   * @param listener
   * @returns
   */
  on<K extends keyof AllClearServiceEventMap>(
    type: K,
    listener: Listener<void>
  ): Disposable {
    if (type === "clear") return this.onClearEmitter.on(listener);
    throw new Error(`not support type:${type}`);
  }
  once<K extends keyof AllClearServiceEventMap>(
    type: K,
    listener: Listener<void>
  ): void {
    if (type === "clear") return this.onClearEmitter.once(listener);
  }
  off<K extends keyof AllClearServiceEventMap>(
    type: K,
    listener: Listener<void>
  ): void {
    if (type === "clear") this.onClearEmitter.off(listener);
  }
}
export interface AllClearServiceEventMap {
  clear: Event;
}
export const allClear = new AllClearService();
