import { Ref, ref, computed, reactive, ComputedRef } from "vue";
/**
 * 読込中を表す isLoading を持つ class
 * <example>
 * ```typescript
 * const l = new Loading();
 * console.log(l.isLoading.value); // false
 * l.isLoading.value = true;
 * console.log(l.isLoading.value); // true
 * l.isLoading.value = false;
 * console.log(l.isLoading.value); // false
 * ```
 * </example>
 */
export class Loading {
  constructor(isLoading = false) {
    this._isLoading.value = isLoading;
  }
  private _isLoading: Ref<boolean> = ref(false);
  /**
   * 読込中を表す プロパティ
   */
  get isLoading(): Ref<boolean> {
    return this._isLoading;
  }
}
/**
 * 読込中を表す isLoading を持つ class
 * <example>
 * ```typescript
 * const sl = new StateLoading();
 * console.log(sl.isLoading.value); // false
 * sl.add("loading");
 * console.log(sl.isLoading.value); // true
 * sl.delete("loading");
 * console.log(sl.isLoading.value); // false
 * ```
 * </example>
 */
export class StateLoading {
  constructor(...items: string[]) {
    this.loadingState = reactive(new Set<string>(...items));
  }
  private loadingState: Set<string>;
  private _isLoading = computed(() => {
    const loadingCount = this.loadingState.size;
    const isLoading = loadingCount > 0;
    return isLoading;
  });
  get isLoading(): ComputedRef<boolean> {
    return this._isLoading;
  }
  clear(): void {
    this.loadingState.clear();
  }
  add(value = ""): boolean {
    if (this.loadingState.has(value)) return false;
    this.loadingState.add(value);
    console.debug("loading add %o", value);
    return true;
  }
  delete(value = ""): boolean {
    console.debug("loading delete %o", value);
    return this.loadingState.delete(value);
  }
}

/**
 * トップレベルの loading を表す class のインスタンス
 */
export const RootLoading = new StateLoading();
