import { assertIsDefined } from "@/utils/asserts";
import { computed, ComputedRef, reactive } from "vue";

export type TitleModel = {
  key: symbol;
  title: string;
};
export class SetTitleService {
  constructor(array: TitleModel[] | undefined = undefined) {
    if (!array || !array.length) return;
    for (const { key, title } of array) this.set(key, title);
  }
  private list: TitleModel[] = reactive([]);
  private map = reactive(new Map<symbol, TitleModel>());
  private _current = computed<string | undefined>(() => {
    const length = this.list.length;
    if (length <= 0) return undefined;
    const title = this.list[length - 1].title;
    console.debug("change title: %o", title);
    return title;
  });
  private _length = computed(() => {
    return this.list.length;
  });
  /**
   * 現在の titleを取得する \
   * 存在すれば `string` / そうでなければ `undefined`
   */
  get current(): ComputedRef<string | undefined> {
    return this._current;
  }
  /**
   * title の設定件数
   */
  get length(): ComputedRef<number> {
    return this._length;
  }
  /**
   * title を設定（変更）する
   * @param key キー
   * @param title タイトル
   * @returns 挿入位置
   */
  set(key: symbol, title: string): number {
    let model: TitleModel;
    if (this.map.has(key)) {
      const _m = this.map.get(key);
      assertIsDefined(_m);
      model = _m;
    } else {
      model = reactive({
        key,
        title,
      });
      this.list.push(model);
      this.map.set(key, model);
    }
    return this.list.indexOf(model);
  }
  /**
   * key を基に削除する
   * @param key
   * @returns list 上の 削除位置 発見できなければ `-1`
   */
  delete(key: symbol): number {
    if (!this.map.has(key)) return -1;
    const model = this.map.get(key);
    assertIsDefined(model);
    this.map.delete(key);
    const indexOf = this.list.indexOf(model);
    if (indexOf <= -1) return indexOf;
    this.list.splice(indexOf, 1);
    return indexOf;
  }
  /**
   * key に於ける title の存在をチェックする
   * @param key
   * @returns 存在すれば `true` / そうでなければ `false`
   */
  has(key: symbol): boolean {
    return this.map.has(key);
  }
  /**
   * key に於ける title 文字列の取得
   * @param key
   * @returns 存在すれば `string` / そうでなければ `undefined`
   */
  get(key: symbol): string | undefined {
    return this.map.get(key)?.title;
  }
}
export const setTitle = new SetTitleService();
