import axios from "axios";
import { Authorize } from "@/components/api-authorization/AuthorizeService";
import router from "@/router";
import { isAxiosError, SystemError } from "./Error";
import { ref } from "vue";
// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  timeout: 60 * 1000, // Timeout
  withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    // if (Authorize.isAuthenticated) {
    //   config.headers = config.headers ?? {};
    //   config.headers["Authorization"] = `Bearer ${Authorize.token}`;
    // }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);
export const isLoginRouting = ref(false);
// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    if (isAxiosError(error)) {
      if (error.response?.status == 401) {
        console.error("logouted: %o", { error });
        Authorize.clear();
        console.debug({ router });
        const redirect = router?.currentRoute?.value?.fullPath ?? null;
        const name = "Login";
        const params = { redirect };
        if (!isLoginRouting.value) {
          router.push({ name, params });
          isLoginRouting.value = true;
        }
        console.debug("router.push(%o)", { name, params });
        // 401 なら AxiosError -> SystemError にする
        return Promise.reject(new SystemError(error.message, { cause: error }));
      }
    }
    // Do something with response error
    return Promise.reject(error);
  }
);

export default _axios;
