import axios from "axios";
import { AuthorizationPaths } from "./ApiAuthorizationConstants";
import { Ref, ref, readonly, DeepReadonly, UnwrapNestedRefs } from "vue";

const _axios = axios.create({
  withCredentials: true,
});
/**
 * ログイン要求
 */
export type LoginUserRequest = {
  /** ユーザID */
  userId: string;
  /** パスワード */
  password: string;
};
/**
 * ログインユーザ応答
 */
export type LoginUserResponse = {
  token: string;
  expiration: string;
};
/**
 * ユーザー情報
 */
export type UserInformation = {
  user: {
    staffId: string;
    staffName: string;
  };
  roles: string[];
};

/**
 * 認証サービス
 */
export class AuthorizeService {
  constructor() {
    this.restore();
  }
  private _token: string | null = null;
  private _user = ref<UserInformation>();
  private _readonlyUser = readonly(this._user);
  private _isAuthenticatedRef = ref(false);
  private _readonlyIsAuthenticated = readonly(this._isAuthenticatedRef);
  private get _isAuthenticated() {
    return this._user.value != null && this._token != null;
  }
  /**
   * 認証済みか否か
   */
  get isAuthenticated(): Readonly<Ref<boolean>> {
    return this._readonlyIsAuthenticated;
  }
  get token(): string {
    if (this._token) return this._token;
    throw new Error("check isAuthenticated.");
  }
  /** 認証済みなら そのユーザ情報を、そうでなければ undefined を */
  get user(): DeepReadonly<UnwrapNestedRefs<Ref<UserInformation | undefined>>> {
    return this._readonlyUser;
  }
  /**
   * ユーザーの取得
   * @returns
   */
  async getUser(): Promise<UserInformation> {
    if (this._user.value) return this._user.value;
    try {
      return await this.refreshUser();
    } catch (e) {
      this.clear();
      throw e;
    }
  }
  async refreshUser(): Promise<UserInformation> {
    try {
      const userResponse = await _axios.get<UserInformation>(
        AuthorizationPaths.CurrentUser
      );
      this._user.value = userResponse.data;
      localStorage.setItem("userInformation", JSON.stringify(this._user));
      return this._user.value;
    } catch (e) {
      this.clear();
      console.error(e);
      throw e;
    } finally {
      this._isAuthenticatedRef.value = this._isAuthenticated;
    }
  }
  async login(userRequest: LoginUserRequest): Promise<UserInformation> {
    const { userId, password } = userRequest;
    const request = new FormData();
    request.append("userId", userId);
    request.append("password", password);
    request.append("useCookie", "true");
    try {
      const loginResponse = await _axios.post<LoginUserResponse>(
        AuthorizationPaths.Login,
        request
      );
      const token = loginResponse.data.token;
      this._token = token;
      localStorage.setItem("token", token);
      return await this.getUser();
    } catch (e) {
      this._token = null;
      localStorage.removeItem("token");
      throw e;
    } finally {
      this._isAuthenticatedRef.value = this._isAuthenticated;
    }
  }
  async logout(): Promise<void> {
    try {
      await _axios.post(AuthorizationPaths.Logout);
    } catch (e) {
      console.error(e);
    } finally {
      this.clear();
    }
  }
  /**
   * ローカルストレージ情報のクリアと トークンのクリアを行う
   */
  clear(): void {
    localStorage.removeItem("token");
    localStorage.removeItem("userInformation");
    this._user.value = undefined;
    this._isAuthenticatedRef.value = this._isAuthenticated;
    console.trace("clear token");
  }
  /**
   * ローカルストレージから復元を試みる
   */
  restore(): void {
    console.group("restore");
    try {
      this._token = null;
      this._user.value = undefined;
      const token = localStorage.getItem("token");
      if (token) {
        this._token = token;
        console.debug("found token", { token });
      }
      if (!this._token) {
        this._isAuthenticatedRef.value = this._isAuthenticated;
        console.debug("not found token", { token });
        return;
      }
      const userStr = localStorage.getItem("userInformation");
      if (userStr && userStr.length > 0)
        try {
          const user = JSON.parse(userStr) as UserInformation;
          if (user) {
            this._user.value = user;
            console.debug("found user", { user });
          }
        } catch (e) {
          console.error(e);
        }
      if (this._isAuthenticated) {
        this._isAuthenticatedRef.value = this._isAuthenticated;
        console.debug("restore resolve.");
        return;
      }
      this.clear();
    } finally {
      console.groupEnd();
    }
  }
}
/**
 * 認証情報のインスタンス
 */
export const Authorize = new AuthorizeService();
