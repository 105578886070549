export default {
  TITLE: "ユーザーマスタ",
  MODAL_TITLE: {
    DEFAULT: "ユーザー情報",
    CREATE: "ユーザー追加",
  },
  CONFIRM_PASSWORD_MODAL: "パスワード再入力",
  CONFIRM_PASSWORD_MESSAGE: "パスワードを再入力してください。",
  DISAGREEMENT_CONFIRM_PASSWORD: "パスワードが一致しません",
  INVALID_PASSWORD: "パスワードは英数字で8桁以上20桁以下である必要があります",
  INVALID_STAFF_ID: "ユーザーIDは数字で1桁以上6桁以下の必要があります",
  DUPLICATE_STAFF_ID: "そのユーザーIDは既に登録されています",
  SEARCH_CONDITION: "検索条件",
  SEARCH_RESULT: "検索結果",
  STAFF_ID: "ユーザーID",
  STAFF_NAME: "ユーザー名",
  PASSWORD: "パスワード",
  IS_NOT_UPLOAD: "クラウド未同期",
  SEARCH: "検索",
  ORDER: "並び替え",
  ORDERLIST: {
    0: "ユーザーID",
    1: "ユーザー名",
  },
  ADD_BUTTON_TITLE: "ユーザー追加",
  STAFF_ID_PLACEHOLDER: "数字6桁",
  BUTTON_LABEL: "⋮",
  OPEN_DETAIL: "詳細画面表示",
  UPLOAD: "クラウド同期",
  UPLOAD_CONFIRM: "クラウドへの同期を実行しますか？",
  CREATE_CONFIRM: "データを追加します。よろしいですか？",
  UPDATE_CONFIRM: "更新してもよろしいですか？",
  DELETE_CONFIRM: "本当に削除してもよろしいですか？",
  NOT_FOUND_DETAIL: "対象のユーザが取得できませんでした",
  BUTTON: {
    CREATE: "追加",
    UPDATE: "更新",
    DELETE: "削除",
  },
};
