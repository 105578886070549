export default {
  TITLE: "プロジェクト一覧",
  MODAL_TITLE: {
    ADD: "プロジェクト追加",
    INFO: "プロジェクト情報",
  },
  SEARCH_CONDITION: "検索条件",
  SEARCH_RESULT: "検索結果",
  PROJECTS_NAME: "プロジェクト名",
  IS_NOT_UPLOAD: "クラウド未同期",
  SEARCH: "検索",
  ORDER: "並び替え",
  OPEN_DETAIL: "詳細画面表示",
  UPLOAD: "クラウド同期",
  UPDATE_CONFIRM: "更新してもよろしいですか？",
  ADD_CONFIRM: "データを追加します。よろしいですか？",
  DELETE_CONFIRM: "本当に削除してもよろしいですか？",
  UPLOAD_CONFIRM: "クラウドへの同期を実行しますか？",
  BUTTON_LABEL: "⋮",
  SAVE: "更新",
  ADD: "追加",
  ORDERLIST: {
    0: "報告書テンプレート",
    1: "プロジェクト名",
  },
  TEMPLATE: "報告書テンプレート",
  NOT_FOUND_DETAIL: "対象のプロジェクトが取得できませんでした",
  BUTTON: {
    CREATE: "追加",
    UPDATE: "更新",
    DELETE: "削除",
  },
  ADD_BUTTON_TITLE: "プロジェクト追加",
};
