import axios from "axios";
import { reactive, Ref, ref } from "vue";
const SettingPaths = {
  Settings: "api/Setting",
};
const _axios = axios.create({
  withCredentials: true,
});

type SettingResult = {
  isValidSettings: boolean;
  isCloud: boolean;
  enableCloudUpload: boolean;
  isDbEnable: boolean;
  existsMeasurementBase: boolean;
  existsTemplateBase: boolean;
  enableInsertCsvInExcel: boolean;
  templateFileNameMaxLength: number;
};

export class SettingService {
  private _loaded = ref(false);
  private _settings: SettingResult = reactive({
    isValidSettings: false,
    isCloud: false,
    enableCloudUpload: false,
    isDbEnable: false,
    existsMeasurementBase: false,
    existsTemplateBase: false,
    enableInsertCsvInExcel: false,
    templateFileNameMaxLength: 0,
  });
  constructor() {
    this.promise = this.restore();
  }
  promise: Promise<void>;
  /**
   * 読込済
   */
  get loaded(): Ref<boolean> {
    return this._loaded;
  }
  /**
   * 設定
   */
  get setting(): SettingResult {
    return this._settings;
  }
  private get storage(): SettingResult | null {
    const storage = globalThis.sessionStorage;
    const settings = storage.getItem("settings");
    if (!settings) return null;
    try {
      return JSON.parse(settings);
    } catch (e) {
      console.error(e);
      return null;
    }
  }
  private set storage(value: SettingResult | null) {
    const storage = globalThis.sessionStorage;
    if (!value) {
      storage.removeItem("settings");
      return;
    }
    storage.setItem("settings", JSON.stringify(value));
  }
  /**
   * 設定を復元する。 storage に無い場合は 読込を試みる
   * @returns 復元完了まで
   */
  restore(): Promise<void> {
    const ls = this.storage;
    if (ls) {
      Object.assign(this._settings, ls);
      this._loaded.value = true;
      return Promise.resolve();
    }
    return this.load();
  }
  /**
   * 設定の再読み込みを行う
   * @returns 完了までの待ち
   */
  reload(): Promise<void> {
    this.storage = null;
    this._loaded.value = false;
    this._settings.enableCloudUpload = false;
    this._settings.isCloud = false;
    this.promise = this.load();
    return this.promise;
  }
  /**
   * 読込の実施
   */
  async load(): Promise<void> {
    const result = await _axios.get<SettingResult>(SettingPaths.Settings);
    Object.assign(this._settings, result.data);
    this.storage = this._settings;
    this._loaded.value = true;
  }
}

/**
 * 設定サービスのインスタンス
 */
export const setting = new SettingService();
