import {
  AlertService,
  ConfirmService,
  PromptService,
} from "./DiagnoticsService";
/**
 * alert の wrappr 関数
 * @param message メッセージ
 * @param singal キャンセル
 * @returns alert を閉じるまでの promise を返す
 * @see globalThis.alert
 * @example
 * ```typescript
 * import { alert } from "@/components/Diagnotics";
 * alert("hello world!"); // alert hello world!
 * ```
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function alert(message?: any, signal?: AbortSignal): Promise<void> {
  if (signal?.aborted) throw new DOMException("aborted", "AbortError");
  return AlertService.push({ message }, signal);
}
/**
 * confirm の wrapper 関数
 * @param message メッセージ
 * @param signal キャンセル
 * @returns confirm の 閉じたときの真偽値を格納した promise を返す
 * @see globalThis.confirm
 * @example
 * ```typescript
 * import { confirm } from "@/components/Diagnotics";
 * const result = confirm("hello world?");
 * console.log(result); // input ok / cancel result boolean!
 * ```
 */
export function confirm(
  message?: string | undefined,
  signal?: AbortSignal
): Promise<boolean> {
  if (signal?.aborted) throw new DOMException("aborted", "AbortError");
  return ConfirmService.push({ message }, signal);
}
/**
 * prompt の wrapper 関数
 * @param message メッセージ
 * @param _default 初期値（？）
 * @param signal キャンセル
 * @returns prompt の 戻り値の文字列を格納した promise を返す
 * @see globalThis.prompt
 * @example
 * ```typescript
 * import { prompt } from "@/components/Diagnotics";
 * const result = prompt("hello world?");
 * console.log(result); // input result string | undefined
 * ```
 */
export function prompt(
  message?: string | undefined,
  _default?: string | undefined,
  signal?: AbortSignal
): Promise<string | null> {
  if (signal?.aborted) throw new DOMException("aborted", "AbortError");
  return PromptService.push({ message, _default }, signal);
}
