import { AxiosError } from "axios";
type ErrorOptions = {
  cause?: Error;
};
/**
 * システムエラー
 */
export class SystemError extends Error {
  get isSystemError(): boolean {
    return true;
  }
  constructor(message: string, options: ErrorOptions | undefined = undefined) {
    super(message);
    if ("cause" in this && options?.cause) {
      try {
        const self = this as { cause?: Error };
        self.cause = options?.cause;
      } catch {
        console;
      }
    }
  }
}
export class MessageError extends Error {
  get isMessageError(): boolean {
    return true;
  }
  constructor(message: string, options: ErrorOptions | undefined = undefined) {
    super(message);
    if ("cause" in this && options?.cause) {
      try {
        const self = this as { cause?: Error };
        /** eslint-disable TS2339: "off" */
        self.cause = options?.cause;
      } catch {
        console;
      }
    }
  }
}
/**
 * error is AxiosError utiltity
 * @param error
 * @returns
 */
export function isAxiosError(error: unknown): error is AxiosError {
  return !!(error as AxiosError).isAxiosError;
}
const AXIOS_TIMEOUT_ERROR_CODE = "ECONNABORTED";
/**
 * error is timeout error utility
 * @param error
 * @returns
 */
export function isTimeout(error: unknown): error is AxiosError {
  if (!isAxiosError(error)) return false;
  return error.code === AXIOS_TIMEOUT_ERROR_CODE;
}
/**
 * error is SystemError utility
 * @param error
 * @returns
 */
export function isSystemError(error: unknown): error is SystemError {
  return !!(error as SystemError).isSystemError;
}
/**
 * error is MessageError utility
 * @param error
 * @returns
 */
export function isMessageError(error: unknown): error is MessageError {
  return !!(error as MessageError).isMessageError;
}
