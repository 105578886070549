import { reactive } from "vue";
import { allClear } from "../AllClearService";
import { Disposable, Listener, TypedEvent } from "../TypedEvent";

export type QueueModel<TModel, TValue = void> = {
  model: TModel;
  signal?: AbortSignal;
  resolver: (result: TValue) => void;
  rejecter: (reject: Error) => void;
};
export type AlertModel = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  message?: any;
};
export type ConfirmModel = {
  message?: string | undefined;
};
export type PromptModel = {
  message?: string | undefined;
  _default?: string | undefined;
};

export class QueueService<TModel, TValue = void> extends EventTarget {
  private list: QueueModel<TModel, TValue>[] = reactive([]);
  promise: Promise<void> = Promise.resolve();
  private readonly onPushEmitter = new TypedEvent<void>();
  private readonly onClearEmitter = new TypedEvent<void>();
  constructor(options: { ignoreForceClear?: boolean } | undefined = undefined) {
    super();
    if (!(options?.ignoreForceClear ?? false))
      allClear.on("clear", () => this.clear());
  }
  async push(model: TModel, signal?: AbortSignal): Promise<TValue> {
    if (signal?.aborted) throw new DOMException("aborted", "AbortError");
    let rejectMethod: (() => void) | undefined;
    try {
      await this.promise;
      const promise = new Promise<TValue>((resolver, rejecter) => {
        const queue: QueueModel<TModel, TValue> = {
          model,
          resolver,
          signal,
          rejecter,
        };
        if (signal) {
          rejectMethod = () => {
            const list = this.list;
            const index = list.indexOf(queue);
            if (index >= 0) list.splice(index, 1);
            rejecter(new DOMException("aborted", "AbortError"));
          };
          signal.addEventListener("abort", rejectMethod);
        }
        this.list.push(queue);
        this.onPushEmitter.emit();
      });
      const queuePromise = async () => {
        try {
          await promise;
        } catch (e) {
          promise;
        }
      };
      this.promise = this.promise.then(queuePromise, queuePromise);
      return await promise;
    } finally {
      if (rejectMethod) signal?.removeEventListener("abort", rejectMethod);
    }
  }
  /**
   * モデル と解決メソッドを取得する
   * @returns
   */
  shift(): [TModel, (result: TValue) => void, AbortSignal?] | undefined {
    const list = this.list;
    const queue = list.shift();
    if (!queue) return undefined;
    const { model, resolver, signal } = queue;
    return [model, resolver, signal];
  }
  clear(): void {
    const list = this.list.splice(0, this.list.length);
    for (const val of list)
      val.rejecter(new DOMException("aborted", "AbortError"));
    this.onClearEmitter.emit();
  }
  on<K extends keyof QueueServiceEventMap>(
    type: K,
    listener: Listener<void>
  ): Disposable {
    if (type === "pushed") return this.onPushEmitter.on(listener);
    else if (type === "clear") return this.onClearEmitter.on(listener);
    throw new Error(`not support type:${type}`);
  }
  off<K extends keyof QueueServiceEventMap>(
    type: K,
    listener: Listener<void>
  ): void {
    if (type === "pushed") this.onPushEmitter.off(listener);
    else if (type === "clear") this.onClearEmitter.off(listener);
  }
}

export interface QueueServiceEventMap {
  pushed: Event;
  clear: Event;
}
export const AlertService = new QueueService<AlertModel>();
export const ConfirmService = new QueueService<ConfirmModel, boolean>();
export const PromptService = new QueueService<PromptModel, string | null>();
